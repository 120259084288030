import Loading from '@components/SuspenseLoading/Loading'
import componentTypes from '@components/componentTypes'
import {Suspense} from 'react'

import usePage from './usePage'

export interface PageProps {
  pageId?: string
  slug?: string | string[]
  componentIdToSsrProps?: {[componentId: string]: any}
}

const SuspenseFallback = () => {
  return <Loading />
}

function PageInner(props: PageProps) {
  const page = usePage({pageId: props.pageId, slug: props.slug})

  if (!page) return null

  return (
    <div>
      {page.activeComponents.map(component => {
        const Component = componentTypes[component.componentTypeId]
        if (!Component) return `Component ${component.componentTypeId} not found`

        return (
          <div key={component._id} className={`ct-${component.componentTypeId}`}>
            <Suspense fallback={<SuspenseFallback />}>
              <Component {...component.options} id={component._id} />
            </Suspense>
          </div>
        )
      })}
    </div>
  )
}

export default function Page(props: PageProps) {
  return (
    <Suspense fallback={null}>
      <PageInner {...props} />
    </Suspense>
  )
}
